import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
// import isEmpty from 'lodash/isEmpty'
import SEO from 'components/seo'
import {css} from '@emotion/core'
import Container from 'components/container'
import Layout from 'components/layout'
import Share from 'components/share'
import SubscribeForm from 'components/forms/subscribe'
import BlogPostFooter from 'components/blog-post-footer'
import UpworkCta from 'components/upwork-cta'
import Markdown from 'react-markdown'
import {fonts} from '../lib/typography'
import config from '../../config/website'
import {bpMaxSM} from '../lib/breakpoints'
import get from 'lodash/get'
// import titleCase from 'ap-style-title-case'

export default function PostPage(props) {
  return <Post {...props} />
}

function Post({data: {site, mdx}}) {
  const {
    title,
    slug,
    description,
    banner,
    bannerCredit,
    noFooter,
    keywords,
    historyLink,
    date,
    editLink,
  } = mdx.fields

  const blogPostUrl = `${config.siteUrl}${slug}`
  const [showEditLink, setShowEditLink] = React.useState(false)

  useEffect(() => {
    fetch(`${process.env.NETLIFY_FUNCTIONS_URL}/what`)
      .then(response => response.json())
      .then(data => {
        setShowEditLink(data.allowed || config.siteUrl.includes('localhost'))
      })
      .catch(() => {
        setShowEditLink(config.siteUrl.includes('localhost'))
      })
  }, [])

  return (
    <Layout
      site={site}
      frontmatter={mdx.fields}
      headerLink="/blog"
      noFooter={noFooter}
      subscribeForm={<SubscribeForm />}
    >
      <SEO
        frontmatter={mdx.fields}
        metaImage={get(mdx, 'fields.banner.childImageSharp.fluid.src')}
        isBlogPost
      />
      <article
        css={css`
          width: 100%;
          display: flex;
          twitter-widget {
            margin-left: auto;
            margin-right: auto;
          }
        `}
      >
        <Container
          css={css`
            padding-top: 20px;
          `}
        >
          <h1
            css={css`
              text-align: center;
              margin-bottom: 20px;
              margin-top: 0;
              font-family: ${fonts.light};
            `}
          >
            {title}
          </h1>
          {banner && (
            <div
              css={css`
                text-align: center;

                p {
                  margin-bottom: 0;
                }
                ${bpMaxSM} {
                  padding: 0;
                }
              `}
            >
              <Img
                fluid={banner.childImageSharp.fluid}
                alt={site.siteMetadata.keywords.join(', ')}
              />
              {bannerCredit ? <Markdown>{bannerCredit}</Markdown> : null}
            </div>
          )}
          <Container noVerticalPadding>
            <a href={showEditLink ? historyLink : ''}>
              <time
                css={{
                  textAlign: 'right',
                  display: 'block',
                  fontSize: '12px',
                  marginBottom: '10px',
                }}
                title="Last Updated Date"
              >
                Posted: {date}
              </time>
            </a>
          </Container>
          <br />
          {description ? <Markdown>{description}</Markdown> : null}
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </Container>
        {/* <SubscribeForm /> */}
      </article>
      {showEditLink && (
        <Container noVerticalPadding>
          <p css={{textAlign: 'right'}}>
            <a target="_blank" rel="noopener noreferrer" href={editLink}>
              Edit post on GitHub
            </a>
          </p>
        </Container>
      )}
      <Container noVerticalPadding css={{marginBottom: 40}}>
        <Share
          url={blogPostUrl}
          title={title}
          twitterHandle={config.twitterHandle}
        />
      </Container>
      {
        <div
          css={css`
            margin-top: 55px;
            display: flex;
            justify-content: center;
          `}
        >
          <Container noVerticalPadding />
        </div>
      }
      {keywords.map(keyword => keyword.toLowerCase()).includes('testing') && (
        <UpworkCta />
      )}
      <Container>
        <BlogPostFooter />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        keywords
      }
    }
    mdx(fields: {id: {eq: $id}}) {
      fields {
        editLink
        historyLink
        title
        date
        noFooter
        description
        plainTextDescription
        author
        banner {
          ...bannerImage720
        }
        bannerCredit
        slug
        keywords
      }
      body
    }
  }
`
